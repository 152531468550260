export const MARKET_1X2 = '1x2';
export const MARKET_1X2_H1 = '1x2_H1';
export const MARKET_1X2_H2 = '1X2_H2';
export const MARKET_BTS = 'BTS';
export const MARKET_BTS_H1 = 'BTS_H1';
export const MARKET_BTS_H2 = 'BTS_H2';
export const MARKET_DC = 'DC';
export const MARKET_DC_BTS = 'DC_BTS';
export const MARKET_DC_H1 = 'DC_H1';
export const MARKET_DC_H2 = 'DC_H2';
export const MARKET_DC_OU50 = 'DC_OU50';
export const MARKET_DNB = 'DNB';
export const MARKET_EH = 'EH';
export const MARKET_HSH = 'HSH';
export const MARKET_HTFT = 'HTFT';
export const MARKET_OE = 'OE';
export const MARKET_OE_H1 = 'OE_H1';
export const MARKET_OE_H2 = 'OE_H2';
export const MARKET_OU = 'OU';
export const MARKET_OU_H1 = 'OU_H1';
export const MARKET_OU_H2 = 'OU_H2';
export const MARKET_RBTS = 'RBTS';
export const MARKET_RTG = 'RTG';
export const MARKET_TGBTS = 'TGBTS';
export const MARKET_NEXTGOAL = 'S1G';

export const ONLINE_MARKETS = {
  MARKET_1X2: 'Match Result',
  MARKET_1X2_H1: 'HalfTime Result',
  MARKET_1X2_H2: '2ndHalf Result',
  MARKET_DC: 'Double Chance',
  MARKET_DC_H1:'HalfTime Double Chance',
  MARKET_DC_H2: '2ndHalf Double Chance',
  MARKET_BTS: 'Both Team To Score',
  MARKET_BTS_H1: 'HalfTime Both Team To Score',
  MARKET_OE: 'Odd/Even',
 MARKET_OU : 'Under/Over',
 MARKET_OU_H1 : 'HalfTime Under/Over',
 MARKET_OU_H2 : '2ndHalf  Under/Over',
}
export const TOP_MARKETS = {
  Tornaments:['PremierLeague','SerieA'],
  Date: ['Today','Tomorrow'],
  Countries: 'England',
}
export const MenuLeaguesData = [
  {
    title: 'Tournaments',
    url: '/',
    submenu: [
      {
        title: 'Premier League',
        url: 'premier_',
        body:'England Premier League'
      },
      {
        title: 'Champions League',
        url: 'ChampionLeague_',
        body:'Europe UEFA Champions League'
      },
      {
        title:'UEFA Europa',
        url:'Europa_',
        body:'Europe UEFA Europa League'
      },
      {
        title: 'LaLiga',
        url: '/LaLiga_',
        body:'Spain La Liga'
      },
      {
        title: 'SerieA',
        url: 'SerieA_',
        body:'Italy Serie A'
      },
      {
        title: 'Bundesliga',
        url: '/Bundesliga_',
        body:'Germany Bundesliga'
      },
      {
        title: 'Ligue1',
        url: 'Ligue1_',
        body:'France Ligue 1'
      },
      {
        title: 'Eredivisie',
        url: 'Eredivisie_',
        body:'Netherlands Eredivisie'
      },
    
      {
        title: 'Countries',
        submenu: [
          {
            title: 'England',
            url: 'England',
          },
          {
            title: 'Italy',
            url: 'Italy',
          },
        ],
      },
    ],
  },
];
export const MobileMenuLeaguesData = [
  {
    title: 'Premier League',
    url: 'premier_',
    body:'England Premier League'
  },
  {
    title: 'Champions League',
    url: 'ChampionLeague_',
    body:'Europe UEFA Champions League'
  },
  {
    title:'UEFA Europa',
    url:'Europa_',
    body:'Europe UEFA Europa League'
  },
  {
    title: 'LaLiga',
    url: '/LaLiga_',
    body:'Spain La Liga'
  },
  {
    title: 'SerieA',
    url: 'SerieA_',
    body:'Italy Serie A'
  },
  {
    title: 'Bundesliga',
    url: '/Bundesliga_',
    body:'Germany Bundesliga'
  },
  {
    title: 'Ligue1',
    url: 'Ligue1_',
    body:'France Ligue 1'
  },
  {
    title: 'Eredivisie',
    url: 'Eredivisie_',
    body:'Netherlands Eredivisie'
  },

  {
    title: 'Countries',
    submenu: [
      {
        title: 'England',
        url: 'England',
      },
      {
        title: 'Italy',
        url: 'Italy',
      },
    ],
  },

];
export const MARKETS_OPTIONS = {
  MARKET_1X2:['1', 'X', '2'],
  MARKET_1X2_H1: ['1', 'X', '2'],
  MARKET_1X2_H2: ['1', 'X', '2'],
  MARKET_DC: ['1X', '12', 'X2'],
  MARKET_DC_H1: ['1X', '12', 'X2'],
  MARKET_DC_H2: ['1X', '12', 'X2'],
  MARKET_BTS: ['Yes', 'No'],
  MARKET_BTS_H1: ['Yes', 'No'],
  MARKET_BTS_H2: ['Yes', 'No'],
  MARKET_OE: ['Odd', 'Even'],
  MARKET_OE_H1: ['Odd', 'Even'],
  MARKET_OE_H2:['Odd', 'Even'],
  MARKET_OU: ['Under', 'Over'],
  MARKET_OU_H1: ['Under', 'Over'],
  MARKET_OU_H2: ['Under', 'Over'],
}