import { liveGames$ } from '.';

const liveGamesStorage = (() => {
  let games = {};

  /**
   * add a game
   * @param {object} game
   */
  const addOrUpdate = (game) => {
    const { matchId } = game;
    if (!matchId) return;

    games = { ...games, [matchId]: game };

    liveGames$.next(games);
  };

  return {
    addOrUpdate
  };
})();

export default liveGamesStorage;
