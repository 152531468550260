import { bets$ } from '.';

const betsStorage = (() => {
  // store all bets here for the lifetime of the app
  const bets = [];

  /**
   * add a selection
   * @param {object} event
   */
  const add = (event) => {
    const index = bets.findIndex((x) => x.matchId === event.matchId);

    if (index < 0) {
      bets.push(event);
    } else if (eventAlreadySelected(event)) {
      remove(event.matchId);
    } else {
      bets.splice(index, 1, event);
    }

    bets$.next(bets);
  };

  const getAll = () => bets;

  /**
   * clears all selections
   */
  const clear = () => {
    while (bets.length > 0) {
      bets.pop();
    }
    bets$.next(bets);
  };

  /**
   * checks if an event is already selected
   * @param {BetEvent} event
   * @returns boolean (true|false)
   */
  const eventAlreadySelected = (event) => {
    return bets.some(
      (x) =>
        x.matchId === event.matchId &&
        x.market === event.market &&
        x.option === event.option
    );
  };

  /**
   * removes a selection
   * @param {string} eventId
   */
  const remove = (eventId) => {
    const index = bets.findIndex((x) => x.matchId === eventId);
    bets.splice(index, 1);
    bets$.next(bets);
  };

  /**
   * update selection value
   */
  const updateOdd = (matchId, newOdd) => {
    const index = bets.findIndex((x) => x.matchId === matchId);
    bets[index].odd = newOdd;
    bets$.next(bets);
  };

  return {
    add,
    clear,
    getAll,
    remove,
    updateOdd
  };
})();

export default betsStorage;
