import { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { combineLatest } from 'rxjs';
import * as Observables from '../rxjs-stores';
import * as api from '../api';
import { MARKET_1X2 } from '../markets';
import {compareAsc, format, parseISO} from 'date-fns';
import { filterGamesBySearchQuery,filterGamesByLeague } from '../functions';

export const useBalance = () => {
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    const sub = Observables.balance$.subscribe({
      next: (value) => setBalance(() => value)
    });
    return () => sub.unsubscribe();
  }, []);

  return {
    balance,
    decrementBalance: (amount) => {
      Observables.balance$.next(Observables.balance$.value - amount);
    },
    incrementBalance: (amount) => {
      Observables.balance$.next(Observables.balance$.value + amount);
    }
  };
};
export const filterGames = (games) =>games.sort(sortByCode);   
const sortByCode = (a, b) =>(a.MatchNo-b.MatchNo)

export const useGames = () => { 
  const [games, setGames] = useState({});
  useEffect(() => {
    const sub = combineLatest([
      Observables.games$,
      Observables.searchKeyWord$,
      Observables.selectedLeague$
    ]).subscribe(([lg, sq,sl]) => {
      const filteredGames =(sq && sq.trim().length > 1) ?
        filterGamesBySearchQuery(lg, sq,sl): lg;  
      setGames(() => filteredGames);
  
    });
    return () => sub.unsubscribe();
  }, []);


  return { games };
};
export const useLeagueGames = () => { 
  const [grouping, setGrouping] = useState(true);
  const [leagueGames, setLeagueGames] = useState({});
 // let selectedLeagueGames={};
  useEffect(() => {
 
    const sub = combineLatest([
      Observables.leagueGames$,
      Observables.searchKeyWord$,
      Observables.selectedLeague$
    ]).subscribe(([lg, sq,sl]) => {
    //  console.log(lg);
  if(sl.length>3){
  const selectedLeagueGames=filterGamesByLeague(lg,sl);
  if (Object.keys(selectedLeagueGames).length >0){
    setLeagueGames(() => selectedLeagueGames);
  }
  else{
    setLeagueGames(() => []);
  }
  }
  else{
   // const sortedGames = (filterGames(lg));
   // console.log(sortedGames);
      const filteredGames =(sq && sq.trim().length > 1) ?
        filterGamesBySearchQuery(lg, sq,sl): lg;
        //console.log(filteredGames); 
      setGrouping(() => false);   
      setLeagueGames(() => filteredGames);
  }
    });

    return () => sub.unsubscribe();
  }, []);


  return { grouping, leagueGames };
};
export const useSelectMarkets = () => {
  const [activeMarket, setActiveMarket] = useState('MARKET_1X2');

  useEffect(() => {
    const sub = Observables.selectedMarket$.subscribe({
      next: (data) => {
        data && setActiveMarket(() => data);
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);
  return { activeMarket };
};

export const useLiveGames = () => {
  const [liveGames, setLiveGames] = useState(null);

  useEffect(() => {
    const sub = Observables.liveGames$.subscribe({
      next: (data) => {
        setLiveGames(() => data);
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);

  return { liveGames };
};

export const useLeagues = () => {
  const [leagues, setLeagues] = useState([]);
  const [leaguesLoaded, setLeaguesLoaded] = useState(false);

  useEffect(() => {
    const sub = Observables.leagues$.subscribe({
      next: (data) => {
        setLeagues(() => data);
        setLeaguesLoaded(() => true);
      }
    });

    return () => {
      sub.unsubscribe();
    };
  }, []);
  // if(matchName!=null||matchName!='')
  // setLeagues(() => findGameByName(leagues,matchName));
  return { leagues, leaguesLoaded };
};

export const useNavigationBarState = () => {
  const [state, setState] = useState({ expanded: false, show: false });

  return {
    expanded: state.expanded,
    setExpanded: (value) => setState((prev) => ({ ...prev, expanded: value })),
    setShow: (value) => setState((prev) => ({ ...prev, show: value })),
    show: state.show
  };
};

export const useOldReceiptDetailsStata = (receiptId) => {
  const [bets, setBets] = useState([]);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [receipt, setReceipt] = useState({});

  useLayoutEffect(() => {
    Promise.all([
      api.getReceiptBets(receiptId),
      api.getReceiptDetails(receiptId)
    ])
      .then(function ([{ data: bets }, { data: receipt }]) {
        setBets(bets);
        setReceipt(receipt);
      })
      .catch((e) => {
        setErrors(() => (Array.isArray(e) ? e : [e.message]));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [receiptId]);

  return { bets, errors, loading, receipt };
};

export const useReceiptState = () => {
  const [state, setState] = useState({
    saving: false,
    success: false,
    errors: []
  });

  return {
    handleClearBets: () => {
      Observables.betsStorage.clear();
    },
    handleErrors: (e) => {
      console.log(e);
      setState((state) => ({
        ...state,
        errors: Array.isArray(e) ? e : [e.message],
        saving: false
      }));
    },
    handleSuccess: () => {
      setState((state) => ({ ...state, saving: false, success: true }));
    },
    setStateBeforeRequest: () => {
      setState((state) => ({
        ...state,
        errors: [],
        saving: true,
        success: false
      }));
    },
    state
  };
};

export const useSelectedBetOption = (betOption, game) => {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    const { BetCategory, BetOption, Line } = betOption ?? {};
    const { OriginalMatchId } = game ?? {};
    const key = [OriginalMatchId, BetCategory, BetOption, Line ?? ''].join('-');

    const sub = Observables.selectedBetOptionKeys$.subscribe({
      next: (keys) => setSelected(() => key in keys)
    });

    return () => {
      sub.unsubscribe();
    };
  }, [betOption, game]);

  return selected;
};

export const useSelectedGameIds = () => {
  const [selectedGameIds, setSelectedGameIds] = useState({});

  useEffect(() => {
    const sub = Observables.gameIds$.subscribe({
      next: setSelectedGameIds
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);

  return { selectedGameIds };
};

export const useSelectionsAggregate = () => {
  const [aggregate, setState] = useState({
    selections: 0,
    stake: 0,
    totalOdds: 0
  });

  useEffect(() => {
    const sub = Observables.selectionsAggregate$.subscribe({
      next: (values) => {
        setState((state) => ({ ...state, ...values }));
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);

  return aggregate;
};

export const useStake = () => {
  const [stake, setStake] = useState(0);

  useEffect(() => {
    const sub = Observables.stake$.subscribe({
      next: (value) => setStake(() => value)
    });
    return () => sub.unsubscribe();
  }, []);

  return stake;
};

export const useStatementsState = () => {
  const [loading, setLoading] = useState(true);
  const [statements, setStatements] = useState([]);
  const { UserId: userId } = useSelector((state) => state.onlineClient);

  useEffect(() => {
    api
      .getStatements(userId)
      .then(({ data }) => {
        setStatements(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [userId]);

  return { loading, statements };
};

export const useTotalSelections = () => {
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const sub = Observables.bets$.subscribe({
      next: (bets) => {
        setTotal(bets.length);
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);

  return {
    total
  };
};
