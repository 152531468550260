const { config } = require('dotenv');

try {
  config();
} catch (err) {
  console.log('dotenv config - error parsing config');
  console.log(err);
}

/**
 *
 * Keep a list of environment variables that we are
 * going to use throught the entire program, plus a
 * few defaults
 *
 */

const {
  REACT_APP_BACKEND_URL,
  REACT_APP_COMPANY,
  REACT_APP_DOMAIN,
  REACT_APP_SERVICE_URL,
  REACT_APP_SOCKET_IO_URL
} = process.env;

// URL for the authentication, user_information and tickets
export const backendUrl = REACT_APP_BACKEND_URL ||'https://smbet.net';//'http://localhost:49191';//'http://sm.betsys.tech'   //'https://testshop.smbet.info';//'http://sm.betsys.tech'   //'https://shop.luckybetssp.com';//'http://localhost:49191';//'https://shop.smbet.ug';//

// company - configure terms and conditions
export const company = REACT_APP_COMPANY || 'SMARTBET';

// domain - configure terms and conditions
export const domain = REACT_APP_DOMAIN || 'smbet.net';

// URL for fetching odds and live odds data
export const serviceUrl =
  REACT_APP_SERVICE_URL || 'https://api-games.smbet.info';
// Socket-IO URL
export const SOCKET_IO_URL =
  REACT_APP_SOCKET_IO_URL || 'wss://socket.smbet.info';
