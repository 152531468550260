import * as env from '../environment';

const axios = require('axios').default;

/**
 * Handle common errors in a similar way for example
 * 400 (BadRequest) - flatten the array of errors
 * @param {Object} response
 * @returns promise
 */
const axiosErrorHandler = (error) => {
  /**
   * check if we have a network application in the error
   */
  if ('message' in error && /network error/i.test(error.message))
    return Promise.reject('Error connecting to server application');

  const {
    response: { status }
  } = error;

  switch (status) {
    /**
     * BadRequest - to be used for handling all model state validation errors
     */
    case 400:
      const {
        response: {
          data: { ModelState: modelState }
        }
      } = error;

      const array = [];
      for (let key in modelState) {
        array.push(...modelState[key]);
      }

      return Promise.reject(array);

    /**
     * Handle unauthorized response
     */
    case 401:
      return Promise.reject('You need to sign in');

    /**
     * Expectation Failed - handle errors that come back with a single reason
     * data - should contain the response message or object
     */
    case 417:
      const {
        response: { data }
      } = error;
      return Promise.reject(data);

    /**
     * Reject everything else we do not understand
     */
    default:
      return Promise.reject(error);
  }
};

/**
 * Handle response especially if it requires transformation
 * @param {AxiosPromiseResponse} response
 */
const axiosResponseHandler = (response) => response;

axios.interceptors.response.use(axiosResponseHandler, axiosErrorHandler);

const defaultHeaders = {
  'Content-Type': 'application/json'
};

const makeRequest = (url, config) => {
  const { data, headers, method } = config || {};
  const defaultConfig = {
    data: data || {},
    headers: headers || defaultHeaders,
    method: method || 'GET'
  };

  // configure an axios instance for the odds-api - nodejs express
  if (url.startsWith(env.serviceUrl)) {
    return axios(url, {
      ...defaultConfig,
      baseURL: /^http.$/.test(url) ? '' : env.serviceUrl
    });
  }

  // configure an axios api for the backend - asp.net mvc 5
  return axios(url, {
    ...defaultConfig,
    baseURL: /^http.$/.test(url) ? '' : env.backendUrl,
    withCredentials: true
  });
};

export const getGames = () => makeRequest(env.serviceUrl);

export const getMyBets = () => makeRequest('/api/Online/MyBets');

export const getOnlineClientInformation = () =>
  makeRequest('/api/Online/UserInfo');
  export const checkUserExists = (username) =>
  makeRequest(`/api/Online/UserExists?userName=${username}`);
  export const generateOTP = (username) =>
    makeRequest(`/api/Online/GetOTP?phoneNumber=${username}`);
 // makeRequest(`https://localhost:5001/api/OtpSecurity/GenerateOTP?phoneNumber=${username}`);
  //makeRequest(`https://mobile.smbet.info/api/OtpSecurity/generateotp?phoneNumber=0752447951`);
  export const getOnlineSettings = () =>
  makeRequest('/api/CompanySettings/OnlineSettings');

export const getReceiptBets = (receiptId) =>
  makeRequest(`/api/Bet/Receipt/${receiptId}`);

export const getReceiptDetails = (receiptId) =>
  makeRequest(`/api/Receipt/GetById/${receiptId}`);

export const getStatements = (userId) =>
  makeRequest(`/api/Statement/User?userid=${userId}`);

export const login = ({ password, rememberMe, username }) => {
  const config = {
    data: {
      password,
      rememberMe: rememberMe ?? true,
      username
    },
    method: 'POST'
  };

  return makeRequest('/api/Account/Login', config);
};
export const changePassWord = ({ OldPassword, NewPassword, ConfirmPassword,username}) => {
  const config = {
    data: {
      OldPassword,
      NewPassword,
      ConfirmPassword,
      username
    },
    method: 'POST'
  };
  return makeRequest('/api/Account/ChangePassword', config);
};
export const resetPassWord = ({  NewPassword, ConfirmPassword,username}) => {
  const config = {
    data: {
      NewPassword,
      ConfirmPassword,
      username
    },
    method: 'POST'
  };
  return makeRequest('/api/Account/SetNewPassWord', config);
};

export const logOff = () =>
  makeRequest('/api/Account/LogOff', { method: 'POST' });

export const postDeposit = (code) =>
  makeRequest(`/api/Online/Deposits?code=${code}`);

export const postTicket = (ticket) => {
  const config = { data: ticket, method: 'POST' };
  return makeRequest('/api/Ticket', config);
};
export const postTicketBooking = (ticket) => {
  const config = { data: ticket, method: 'POST' };
  return makeRequest('/api/Ticket/Booking', config);
};
export const postMobileMoneyDeposit = ({ phoneNumber,amount }) => {
  const config = {
    data: {
      amount,
      phoneNumber,
    },
    method: 'POST'
  };
  return makeRequest('/api/Online/MobileMoneyDepositRequest', config);
};

export const postWithdraw = ({ amountpaid, password, username }) => {
  const config = {
    data: {
      amountpaid,
      password,
      username
    },
    method: 'POST'
  };
  return makeRequest('/api/Online/WithdrawRequest', config);
};

export const register = ({ confirmPassword, email, password, phoneNumber }) => {
  const config = {
    data: {
      confirmPassword,
      email: email === '' ? null : email,
      password,
      phoneNumber
    },
    method: 'POST'
  };

  return makeRequest('/api/Account/Register', config);
};

export const revalidateLogin = () => makeRequest('/api/Account/CheckLogin');

export default makeRequest;
