export const CUSTOM_EVENTS = {
  DOM_CONTENT_LOADED: 'DOMContentLoaded',
  GAME_MODE_UPDATED: 'GameModeUpdated',
  KEY_PRESS: 'keypress',
  SETTINGS_RECEIVED: 'SettingsReceived'
};

export const KEY_CODES = {
  ENTER_KEY_CODE: 13,
  ESCAPE_KEY_CODE: 27
};

export const MATCH_CODE_REGEX = /^[0-9]*$/;

export const MODES = {
  LIST_GAMES_MODE: 'ListGamesMode',
  SHOW_GAME_DETAILS_MODE: 'ShowGameDetailsMode'
};
