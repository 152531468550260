import { io } from 'socket.io-client';
import { liveGamesStorage } from '../rxjs-stores';
import { SOCKET_IO_URL } from '../environment';

const addLiveMatch = (game) => {
 // console.log(game);
  /* const parsedGame = {
    awayTeam: game.t2.n,
    bookMakerId: game.bm,
    homeTeam: game.t1.n,
    matchId: game.id,
    odd: game.bt,
    period: game.pn,
    scores: game.sc,
    matchTime: game.tss
  }; */
  liveGamesStorage.addOrUpdate(game);
};

const onConnect = () => {
  console.log('connected to socket');
};

const onLiveFeedsEvent = (payload) => {
  if (!payload) return;
  try {
    addLiveMatch(payload);
  } catch (err) {
    console.log('error parsing json from server');
  }
};

const connect = (() => {
  let socket;

  /**
   * returns a set up function,
   * closes over the socket
   */
  return () => {
    if (socket) return socket;

    // initialize if not already initialized
    socket = io(SOCKET_IO_URL);
    socket.on('connect', onConnect);
    socket.on('live-feeds-event', onLiveFeedsEvent);
  };
})();

export default connect;
